@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../public/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");
}


.col-style {
  padding-left: 8px;
  padding-right: 8px;
}

body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  /* font-family: "Open Sans"; */
  font-family: "IBM Plex Sans";
}

body {
  background: #EEEAE7;
}

.wrap-privacy-policy .ant-modal-close-x {
  display: none;
}

.ant-form-item-explain-error {
  font-size: 12px;
}

.img-logo-business-deal {
  margin-left: auto;
  margin-right: auto;
}

.img-logo-nn {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.nn-container {
  background: #EEEAE7;
  padding: 0px 12px 0px 12px;
}

.nn-card {
  max-width: 576px;
  border: 1px solid #FCFCFC;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  margin-left: auto;
  margin-right: auto;
  min-height: 300px;
  padding: 64px 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.ant-form-item {
  margin-bottom: 36px !important;
}

.nn-fm-header {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nn-fm-detail {
  color: var(--neutral-60, #737C89);
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
}

.nn-fm-subheader {
  color: var(--neutral-grey-10, #17171A);
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 24px;
}

.nn-fm-groupheader {
  color: #737270;
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: .75rem;
}

.nn-fm-footer {
  color: var(--neutral-60, #737C89);
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 12px;
}

.nn-fm {
  text-align: left;
}

.nn-steps-current {
  text-align: left;
  color: var(--primary-orange, #F28626);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.nn-steps-view {
  padding-top: 4px;
  padding-bottom: 8px;
}

.nn-space-between-xl {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}


.ant-steps-item-icon {
  background: #fff !important;
  border: 1px solid #C8C8CD !important;
}

.ant-steps-icon {
  font-weight: 600;
  color: #C8C8CD !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: #F28626 !important;
  border: 1px solid #F28626 !important;
}

.ant-steps-item-finish .ant-steps-icon {
  font-weight: 600;
  color: #fff !important;
}


.ant-steps-item-active .ant-steps-item-icon {
  background: #fff !important;
  border: 1px solid #F28626 !important;
}

.ant-steps-item-active .ant-steps-icon {
  font-weight: 600;
  color: #F28626 !important;
}

.text-center {
  text-align: center !important;
}

.main-container {
  display: flex;
  height: 100vh;
}


.public-form {
  padding: 64px 0;
  margin: 0px auto;
  width: 100%;
  text-align: start;
}

.width-70p {
  width: 70%;
}

.label.as-placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  width: 90%;
}

.public-form .ui-form {
  max-width: 576px;
  margin: 0px auto;
  padding: 32px;
  transition: all 400ms ease 0s;
  background-color: rgb(255, 255, 255);
  color: rgb(31, 31, 31);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 8px;
  border-radius: 4px;
  margin-bottom: 60px;
}


.ui-h1 {
  font-style: normal;
  margin-bottom: 16px;
  line-height: 28px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 24px;
  text-align: left;
}

.ui-h3 {
  font-style: normal;
  word-break: break-word;
  margin-bottom: 16px;
  margin-top: 22px;
  line-height: 18px;
  text-align: left;
  font-weight: bold;
}

.ui-h5 {
  line-height: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  color: rgb(128, 128, 128);
  text-align: left;
}

.ant-form-item {
  margin-bottom: 12px;
}

.logo-container {
  text-align: center;
  margin-bottom: 25px;
}

.img-logo {
  width: 100px;
  height: 100px;
}

.event-container {
  padding: 50px;
  color: white;
  text-shadow: 0px 0px 10px black;
}


.link {
  cursor: pointer;
  color: #1677ff;
  text-decoration: underline;
  display: inline-block;
}

.checkbox-container {
  margin-bottom: 20px;
  display: flex;
}


.text-checkbox {
  margin-left: 10px;
}



.ant-modal-footer button {
  width: 100%;
}

.app-ui-main {
  /* display: table; */
  width: 100%;
  height: 100vh;
  display: flex !important;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;

}

.app-ui-left {
  display: table-cell;
  overflow: auto;
  width: 100%;
  height: 100% !important;
}

.app-ui-right {
  vertical-align: top;
  background-color: rgb(216, 221, 230);
  width: 100%;
  height: 100% !important;
  color: rgba(255, 255, 255, 0.6);
}

.public-event {
  width: 100%;
  height: 100%;
}

.privacy-container {
  height: 60vh;
  max-height: 600px;
  overflow-y: scroll;
}

.site-layout {
  margin-left: 200px;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

.ant-picker-focused {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

.ant-btn-circle-icon {
  min-height: 20px !important;
}

.card-drop-here {
  width: 100%;
  height: 190px;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 8px;
  text-align: center;
}

.card-drop-here:hover,
.card-drop-here:active {
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.card-drop-here.disabled {
  opacity: 0.6;
}

.ant-modal-confirm-error .ant-modal-confirm-body {
  display: block;
  text-align: center;
  padding-top: 20px;
}

.ant-modal-confirm-error .ant-modal-confirm-body .anticon {
  margin-left: auto;
  margin-right: auto;
  font-size: 68px;
}

.ant-modal-confirm-error .ant-modal-confirm-body .ant-modal-confirm-paragraph {
  padding-top: 20px;
}

.ant-modal-confirm-error .ant-modal-confirm-btns {
  text-align: center;
}


.ant-modal-confirm-success .ant-modal-confirm-body {
  display: block;
  text-align: center;
  padding-top: 20px;
}

.ant-modal-confirm-success .ant-modal-confirm-body .anticon {
  margin-left: auto;
  margin-right: auto;
  font-size: 68px;
}

.ant-modal-confirm-success .ant-modal-confirm-body .ant-modal-confirm-paragraph {
  padding-top: 20px;
}

.ant-modal-confirm-success .ant-modal-confirm-btns {
  text-align: center;
}


.ant-modal-confirm-warning .ant-modal-confirm-body {
  display: block;
  text-align: center;
  padding-top: 20px;
}

.ant-modal-confirm-warning .ant-modal-confirm-body .anticon {
  margin-left: auto;
  margin-right: auto;
  font-size: 68px;
}

.ant-modal-confirm-warning .ant-modal-confirm-body .ant-modal-confirm-paragraph {
  padding-top: 20px;
}

.ant-modal-confirm-warning .ant-modal-confirm-btns {
  text-align: center;
}

.app-ui-left {
  padding-right: 12px;
}

.pc-mode {
  display: flex;
}

.mobile-mode {
  display: none;
}

.phone-box{
  display: flex;
  gap:5px;
}

.phone-box .ant-select-selector{
  padding: 0 0 0 15px !important;
} 

.phone-box .ant-form-item:nth-child(1){
  width: 170px;
}

.phone-box .ant-form-item:nth-child(2){
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .public-form {
    padding: 40px 8px;
    /* width: 84%; */
  }

  .site-layout {
    margin-left: 0px;
  }

  .app-ui-left {
    width: 100%;
  }

  .app-ui-right {
    display: none;
  }

  .nn-card {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .pc-mode {
    display: none !important;
  }

  .mobile-mode {
    display: flex !important;
  }

  .public-form .ui-form {
    padding: 24px;
    /* margin-right: 12px;
        margin-left: 12px; */
  }

  .app-ui-left {
    padding-right: 0px;
  }

  .public-form {
    padding: 60px 12px;
    margin: 0px auto;
    width: auto;
    text-align: start;
  }

  .header-h1 {
    font-size: 24px;
  }

  .header-h2 {
    font-size: 20px;
  }

}



@media only screen and (max-width: 532px) {

  /* .ant-form label {
      font-size: 12px;
  } */
  /* .ant-input {
      padding: 6px 23px;
  } */
  .ant-steps.ant-steps-small .ant-steps-item-icon {
    margin-inline: 0px 0px;
  }

  .ant-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-inline-start: 0px;
  }

  .ant-steps.ant-steps-small .ant-steps-item-title {
    padding-inline-end: 0px;
  }

  .phone-box{
    display: block;
  }
  
  .phone-box .ant-form-item:nth-child(1){
    width: 100%;
  }
  
  .phone-box .ant-form-item:nth-child(2){
    width: 100%;
  }
}
